.add-btn {
   text-decoration: none;
   width: 100%;
   display: flex;
   justify-content: center;
}

@media screen and (min-width: 768px){
   .add-btn {
      width: 25%;
   }
}