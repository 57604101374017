.registration {
   justify-content: center;
   padding: 15px;
}

/*  REGISTRATION FORM CSS */
.registration-form {
   padding: 16px;
   background-color: white;
}
 
 /* Full-width input fields */
input[type=text], input[type=password] {
   width: 100%;
   padding: 15px;
   margin: 5px 0 22px 0;
   display: inline-block;
   border: none;
   background: #f1f1f1;
}
 
input[type=text]:focus, input[type=password]:focus {
   background-color: #ddd;
   outline: none;
}
 
hr {
   border: 1px solid #f1f1f1;
   margin-bottom: 25px;
}

.textarea:focus{
   box-shadow: 0 0 5px rgba(81, 203, 238, 1);
   border: 1px solid rgba(81, 203, 238, 1);
   transition: all 0.30s ease-in-out;
}
 
/* Style for the submit button */
.register-btn {
   background-color: #4CAF50;
   color: white;
   padding: 16px 20px;
   margin: 8px 0;
   border: none;
   cursor: pointer;
   width: 100%;
   opacity: 0.9;
   text-transform: uppercase;
}
 
.register-btn:hover {
   opacity: 1;
}
 
a {
   color: dodgerblue;
}

@media screen and (min-width: 768px){
   .registration {
      padding-left: 45px;
      padding-right: 45px;
   }
}