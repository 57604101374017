textarea {
   display: flex;
   resize: none;
   width: 100%;
   height: 100px;
   margin-top: 10px;
   padding: 15px;
   background: #f1f1f1;
   border: none;
   font-family: "Cantarell", sans-serif;
}

textarea:focus {
   background-color: #ddd;
   outline: none;
}

.cancel-btn {
   background-color: rgb(175, 76, 76);
}