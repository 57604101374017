.login {
   justify-content: center;
   padding: 15px;
}

@media screen and (min-width: 768px){
   .login {
      padding-left: 45px;
      padding-right: 45px;
   }
}