/* FROM MY WIREFRAM */
.navbar {
   background-color: #444;
   border: 1px solid rgba(0, 0, 0, 0.2);
   padding-bottom: 10px;
   position: fixed;
   width: 100%;
   top: 0;
   z-index: 5;
}

.main-nav {
   list-style-type: none;
   display: none;
}

.nav-links,
.logo {
   text-decoration: none;
   color: rgba(255, 255, 255, 0.7);
}

.main-nav li {
   text-align: center;
   margin: 15px auto;
}

.logo {
   color: white;
   display: inline-block;
   font-size: 22px;
   margin-top: 10px;
   margin-left: 20px;
}

.navbar-toggle {
   position: absolute;
   top: 10px;
   right: 20px;
   cursor: pointer;
   color: rgba(255, 255, 255, 0.8);
   font-size: 24px;
}

.active {
   display: block;
}

/*  END MY WIREFRAME COPY  */

@media screen and (min-width: 768px) {
   .navbar {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0;
      align-items: center;
      padding-right: 40px;
      padding-left: 40px;
   }

   .main-nav {
      display: flex;
      margin-right: 30px;
      flex-direction: row;
      justify-content: flex-end;
   }

   .main-nav li {
      margin: 0;
   }

   .nav-links {
      margin-left: 40px;
   }

   .logo {
      margin-top: 0;
   }

   .navbar-toggle {
      display: none;
   }

   .logo:hover,
   .nav-links:hover {
      color: rgba(255, 255, 255, 1);
   }

   .header a {
      padding-left: 90px;
   }
   
   .header__not-logged-in {
      padding-right: 90px;
      
   }
   .header h1 {
     font-size: 18px;
   }
}