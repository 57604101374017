.activities-list {
   justify-content: center;
   padding: 15px;
}

.add-activity {
   display: flex;
   justify-content: center;
}

.add-btn {
   background-color: #4CAF50;
   color: white;
   padding: 10px 10px;
   margin: 8px 0;
   border: none;
   cursor: pointer;
   width: 100%;
   opacity: 0.9;
   text-transform: uppercase;
}
 
.add-btn:hover {
   opacity: 1;
}

.filterable-list{
   justify-content: center;
   margin-left: auto;
   margin-right: auto;
   padding-bottom: 35px;
   width: 90%;
}

@media screen and (min-width: 768px){
   .activies-list, .filterable-list {
      width: 75%;
   }
   .search-bar {
      flex-direction: row;
   }

   .search-box {
      width: 60%;
   }
   
   .add-btn {
      width: 70%;
   }
}

@media screen and (min-width: 640px) {
   .add-btn {
      width: 35%;
   }
}