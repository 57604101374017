.main {
   display: flex;
   flex-direction: column;
   justify-content: center;
}

.welcome {
   justify-content: center;
   padding: 15px;
}

.welcome-btn {
   background-color: #4CAF50;
   color: white;
   padding: 16px 20px;
   margin: 8px 0;
   border: none;
   cursor: pointer;
   width: 150px;
   opacity: 0.9;
   text-transform: uppercase;
}

.welcome-btn:hover {
   background-color: rgb(38, 172, 43);
   box-shadow: 1 1 rgb(44, 44, 44);
}

.sign-up-link {
   text-decoration: none;
   color: white;
}

@media screen and (min-width: 768px){
   .welcome {
      padding-left: 45px;
      padding-right: 45px;
   }
}